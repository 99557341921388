(<template>
  <header class="s-header">
    <template v-for="(menu, index) in leftMenu">
      <router-link v-if="menu.type === 'router-link' && menu.condition"
                   :key="index"
                   :to="menu.action"
                   :class="menu.classNames">{{ menu.name }}</router-link>
      <button v-else-if="menu.type === 'button' && menu.condition"
              :key="'btn-' + index"
              :class="menu.classNames"
              @click="menu.action">{{ menu.name }}</button>
    </template>
    <p v-if="$isGodmode()"
       class="sk-btn sk-btn--red s-header__godmode-btn">{{ $gettext('Admin mode') }}</p>
    <div class="s-header__account-cont">
      <div class="s-header__invitations-cont">
        <router-link :to="jobInvitationsPageLink"
                     :class="{'new-job-invitation': newInvitation}"
                     class="s-header__usual-link bell-icon">{{ $gettext('Invitations') }}</router-link>
      </div>
      <!-- <button class="s-header__usual-link"
              @click="openHelpcenterModal">
        {{ $gettext('Help center') }}
      </button> -->
      <a :href="'https://support.salita.no/collection/362-tolk'"
         target="_blank"
         class="b-header__usual-link">{{ $gettext('Help center') }}</a>
      <button ref="accountBtn"
              class="s-header__account-btn"
              @click.stop="toggleSubnav">
        {{ $gettext('My account') }}
        <tt-avatar :own="true"
                   class="s-header__account-avatar" />
      </button>
      <nav v-if="subnavShown"
           class="s-header__subnav">
        <template v-for="(menu,index) in rightMenu">
          <router-link v-if="menu.type === 'router-link' && menu.condition"
                       :key="index"
                       :to="menu.action"
                       :class="menu.classNames">
            <tt-avatar v-if="menu.hasAvatar"
                       :own="true"
                       class="s-header__nav-avatar" />
            {{ menu.name }}</router-link>
          <button v-else-if="menu.type === 'button' && menu.condition"
                  :key="'btn-' + index"
                  :class="menu.classNames"
                  @click="menu.action">{{ menu.name }}</button>
          <a v-else-if="menu.type === 'link' && menu.condition"
             :key="'link-' + index"
             :class="menu.classNames"
             :href="menu.href">{{ menu.name }}</a>
        </template>
      </nav>
    </div>
    <h1 class="s-header__page-name">{{ mobileTitle }}</h1>
  </header>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';

  export default {
    data() {
      return {
        openedSubnav: false,
        clientWidth: 0
      };
    },
    computed: {
      ...mapState('s_DashboardStore', {
        newInvitation: (state) => state.newInvitation
      }),
      ...mapGetters('UserInfoStore', [
        'userUid',
        'userIsInterpreter',
        'userIsSeller',
        'userHasInterpretation'
      ]),
      leftMenu() {
        if (this.userIsSeller && !this.userHasInterpretation) {
          return this.newTranslationOnlyLeftMenu;
        } else if (!this.userIsSeller && this.userHasInterpretation) {
          return this.interpretationOnlyLeftMenu;
        } else if (this.userHasInterpretation && this.userIsSeller) {
          return this.bothLeftMenu;
        }
      },
      rightMenu() {
        if (this.userIsSeller && !this.userHasInterpretation) {
          return this.newTranslationOnlyRightMenu;
        } else if (!this.userIsSeller && this.userHasInterpretation) {
          return this.interpretationOnlyRightMenu;
        } else if (this.userHasInterpretation && this.userIsSeller) {
          // same as interpretation only
          return this.interpretationOnlyRightMenu;
        }
      },
      interpretationOnlyLeftMenu() {
        return [
          {
            name: '',
            type: 'router-link',
            classNames: 's-header__logo',
            action: this.$makeRoute({name: 'ServerHome'}),
            condition: !this.showBackBtn
          },
          {
            name: this.$gettext('Back'),
            type: 'button',
            classNames: 's-header__back-btn',
            action: this.historyBack,
            condition: this.showBackBtn
          },
          {
            name: this.$gettext('Home'),
            type: 'router-link',
            classNames: 's-header__usual-link s-header__usual-link--first',
            action: this.$makeRoute({name: 'ServerHome'}),
            condition: true
          },
          {
            name: this.$gettext('Calendar'),
            type: 'router-link',
            classNames: 's-header__usual-link',
            action: this.calendarLink,
            condition: true
          },
          {
            name: this.$gettext('Assignments'),
            type: 'router-link',
            classNames: 's-header__usual-link s-header__usual-link--last',
            action: this.assignmentsLink,
            condition: true
          }
        ];
      },
      newTranslationOnlyLeftMenu() {
        return [
          {
            name: '',
            type: 'router-link',
            classNames: 's-header__logo',
            action: this.$makeRoute({name: 'ServerHome'}),
            condition: !this.showBackBtn
          },
          {
            name: this.$gettext('Back'),
            type: 'button',
            classNames: 's-header__back-btn',
            action: this.historyBack,
            condition: this.showBackBtn
          },
          {
            name: this.$pgettext('translation', 'Translation'),
            type: 'router-link',
            classNames: 's-header__usual-link s-header__usual-link--last',
            action: this.projectsLink,
            condition: true
          }
        ];
      },
      bothLeftMenu() {
        return [
          {
            name: '',
            type: 'router-link',
            classNames: 's-header__logo',
            action: this.$makeRoute({name: 'ServerHome'}),
            condition: !this.showBackBtn
          },
          {
            name: this.$gettext('Back'),
            type: 'button',
            classNames: 's-header__back-btn',
            action: this.historyBack,
            condition: this.showBackBtn
          },
          {
            name: this.$gettext('Interpretation'),
            type: 'router-link',
            classNames: 's-header__usual-link s-header__usual-link--first',
            action: this.$makeRoute({name: 'ServerHome'}),
            condition: true
          },
          {
            name: this.$pgettext('translation', 'Translation'),
            type: 'router-link',
            classNames: 's-header__usual-link',
            action: this.projectsLink,
            condition: true
          },
          {
            name: this.$gettext('Calendar'),
            type: 'router-link',
            classNames: 's-header__usual-link',
            action: this.calendarLink,
            condition: true
          },
          {
            name: this.$gettext('Assignments'),
            type: 'router-link',
            classNames: 's-header__usual-link s-header__usual-link--last',
            action: this.assignmentsLink,
            condition: true
          }
        ];
      },
      interpretationOnlyRightMenu() {
        return [
          {
            name: this.$gettext('My profile'),
            type: 'router-link',
            classNames: 's-header__subnav-link s-header__subnav-link--my-profile',
            action: this.$makeRoute({name: 'ServerProfileEdit', params: {id: this.userUid}}),
            hasAvatar: true,
            condition: true
          },
          {
            name: this.$gettext('Accessibility'),
            type: 'button',
            classNames: 's-header__subnav-link s-header__subnav-link--accessibility',
            action: this.openAccessibilityModal,
            condition: true
          },
          {
            name: this.$gettext('My interpreter ID'),
            type: 'button',
            classNames: 's-header__subnav-link s-header__subnav-link--interpreter-id',
            action: this.openInterpreterIdModal,
            condition: true
          },
          {
            name: this.$gettext('Calendar'),
            type: 'router-link',
            classNames: 's-header__subnav-link s-header__subnav-link--calendar',
            action: this.calendarLink,
            condition: true
          },
          {
            name: this.$gettext('Documents'),
            type: 'router-link',
            classNames: 's-header__subnav-link s-header__subnav-link--docs',
            action: this.$makeRoute({name: 'ServerDocuments'}),
            condition: true
          },
          {
            name: this.$gettext('Payout preferences'),
            type: 'router-link',
            classNames: 's-header__subnav-link s-header__subnav-link--payments',
            action: this.$makeRoute({name: 'ServerPayoutsAll'}),
            condition: true
          },
          {
            name: this.$gettext('Change password'),
            type: 'router-link',
            classNames: 's-header__subnav-link s-header__subnav-link--password',
            action: this.$makeRoute({name: 'ServerChangePassword'}),
            condition: true
          },
          {
            name: this.$gettext('Settings'),
            type: 'router-link',
            classNames: 's-header__subnav-link s-header__subnav-link--pref',
            action: this.$makeRoute({name: 'ServerPreferencesAvailability'}),
            condition: true
          },
          // TODO: Original page is not working anymore and for proving everything is works suppliers must pass video test
          {
            name: this.$gettext('Network test'),
            type: 'link',
            classNames: 's-header__subnav-link s-header__subnav-link--net-test',
            href: 'https://test.webrtc.org',
            condition: false
          },
          {
            name: this.$gettext('Video test'),
            type: 'router-link',
            classNames: 's-header__subnav-link s-header__subnav-link--video-test',
            action: this.$makeRoute({name: 'ServerTestVideo'}),
            condition: true
          },
          {
            name: this.$gettext('Sign out'),
            type: 'button',
            classNames: 's-header__subnav-link s-header__subnav-link--sign-out',
            action: this.logOut,
            condition: true
          }
        ];
      },
      newTranslationOnlyRightMenu() {
        return [
          {
            name: this.$gettext('My profile'),
            type: 'router-link',
            classNames: 's-header__subnav-link s-header__subnav-link--my-profile',
            action: this.$makeRoute({name: 'ServerProfileEdit', params: {id: this.userUid}}),
            hasAvatar: true,
            condition: true
          },
          {
            name: this.$gettext('Documents'),
            type: 'router-link',
            classNames: 's-header__subnav-link s-header__subnav-link--docs',
            action: this.$makeRoute({name: 'ServerDocuments'}),
            condition: true
          },
          {
            name: this.$gettext('Payout preferences'),
            type: 'router-link',
            classNames: 's-header__subnav-link s-header__subnav-link--payments',
            action: this.$makeRoute({name: 'ServerPayoutsAll'}),
            condition: true
          },
          {
            name: this.$gettext('Change password'),
            type: 'router-link',
            classNames: 's-header__subnav-link s-header__subnav-link--password',
            action: this.$makeRoute({name: 'ServerChangePassword'}),
            condition: true
          },
          {
            name: this.$gettext('Sign out'),
            type: 'button',
            classNames: 's-header__subnav-link s-header__subnav-link--sign-out',
            action: this.logOut,
            condition: true
          }
        ];
      },
      calendarLink() {
        return this.$makeRoute({
          name: 'ServerCalendar',
          query: {
            view: 'week',
            date: this.$moment().format('YYYY-MM-DD')
          }
        });
      },
      projectsLink() {
        return this.$makeRoute({
          name: 'ServerAllProjects',
          query: {
            availableStatus: 'new',
            acceptedStatus: 'active'
          }
        });
      },
      assignmentsLink() {
        return this.$makeRoute({
          name: 'ServerAllAssignments',
          query: {
            view: 'current',
            sortConfirmedBy: 1,
            sortAvailableBy: 1,
            pageConfirmedBy: 1,
            pageAvailableBy: 1
          }
        });
      },
      jobInvitationsPageLink() {
        return this.$makeRoute({name: 'ServerAllInvitations'});
      },
      subnavShown() {
        return this.openedSubnav && this.clientWidth >= 768;
      },
      mobileTitle() {
        return this.$store.state.mobileTitle || this.$getString('metaData', 'shortTitle');
      },
      showBackBtn() {
        return this.$store.state.subPage && this.$store.state.GlobalStore.touchScreen;
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      openAccessibilityModal() {
        this.closeModal();
        this.$store.commit('ModalStore/setModal', {
          component: 'accessibility-modal',
        });
      },
      // Do we need this component?
      // openHelpcenterModal() {
      //   this.closeModal();
      //   this.$store.commit('ModalStore/setModal', {
      //     component: 'helpcenter-modal',
      //   });
      // },
      toggleSubnav(e) {
        this.toggleAbsoluteBlocks();
        this.openedSubnav = !this.openedSubnav;
        if (e.target.className.indexOf('s-header__account-') != -1) {
          this.$refs.accountBtn.blur();
        }
      },
      toggleAbsoluteBlocks(e) {
        if (e && (e.target.className.indexOf('s-header__account-') != -1 && e.target.className.indexOf('s-header__notifications-') != -1)) {
          return;
        }
        this.openedSubnav = false;
      },
      setClientWidth() {
        this.clientWidth = document.documentElement.clientWidth;
      },
      historyBack() {
        if (document.referrer && document.referrer.indexOf('sms') != -1) {
          this.$router.push('/');
        } else {
          const path = this.$route.fullPath;
          this.$router.back();
          setTimeout(() => {
            if (this.$route.fullPath == path) this.$router.push('/');
          }, 500);
        }
      },
      logOut() {
        this.$store.dispatch('TTAuthStore/logOut').then(() => {
          // set cookie for preventing mobile app redirect after clicking on logout button
          this.$cookieManager.setCookie('preventMobileAppRedirect', true, {path: '/', expires: 2592000});
          setTimeout(() => {
            this.$goToExternalLoginPage();
          }, 0);
        });
      },
      openInterpreterIdModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'interpreter-id-modal',
          width: 333
        });
      }
    },
    mounted() {
      this.setClientWidth();
      document.addEventListener('click', this.toggleAbsoluteBlocks);
      window.addEventListener('resize', this.setClientWidth);
    }
  };
</script>

<style scoped>
  .s-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    width: 100%;
    height: 60px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 1px 2px 0 rgba(0, 34, 102, 0.2);
  }

  .input-focused .s-header {
    position: absolute;
  }

  .s-header__logo {
    position: relative;
    display: block;
    width: 60px;
    background-image: url(~@assets/imgs/logos/single_logo_red.svg);
    background-image: var(--image-single-logo);
    background-position: 50% 50%;
    background-size: 30px auto;
    background-repeat: no-repeat;
  }

  .s-header__logo::after {
    content: '';
    position: absolute;
    top: 15px;
    right: 0;
    display: block;
    width: 1px;
    height: 30px;
    background-color: #e4e7ee;
  }

  .s-header__godmode-btn {
    display: inline-block;
    align-self: center;
    width: auto;
    height: 35px;
    margin-left: 10px;
    padding: 0 10px;
  }

  .s-header__usual-link {
    display: inline-block;
    padding: 0 10px;
    line-height: 60px;
  }

  .s-header__usual-link:focus {
    z-index: 1;
    outline: 2px solid #3af;
  }

  .s-header__usual-link:hover {
    background-color: #f4f6f8;
  }

  .s-header__usual-link:active {
    background-color: #e8e9ed;
    outline: none;
  }

  .s-header__usual-link--first {
    padding-left: 15px;
  }

  .s-header__usual-link--last {
    margin-right: auto;
  }

  .s-header__usual-link.router-link-active {
    color: #646d8c;
    color: var(--color-primary);
  }

  .s-header__account-cont {
    display: flex;
  }

  .s-header__account-btn {
    position: relative;
    display: inline-block;
    padding-right: 45px;
    padding-left: 10px;
    font-size: 14px;
  }

  .s-header__account-btn:focus {
    z-index: 1;
    outline: 2px solid #3af;
  }

  .s-header__account-btn:hover {
    background-color: #f4f6f8;
  }

  .s-header__account-btn:active {
    background-color: #e8e9ed;
    outline: none;
  }

  .s-header__account-avatar {
    position: absolute;
    top: 15px;
    right: 10px;
    width: 30px;
    height: 30px;
  }

  .s-header__invitations-cont {
    position: relative;
    display: flex;
  }

  .bell-icon {
    padding-left: 35px;
    background-image: url(~@assets/imgs/nav_icons/bell_icon.svg);
    background-position: 5% center;
    background-size: 25px auto;
    background-repeat: no-repeat;
  }

  .bell-icon.new-job-invitation::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 25px;
    box-sizing: border-box;
    padding: 3px;
    border-radius: 100%;
    background: var(--color-primary);
  }

  .s-header__subnav {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
    display: inline-block;
    overflow: auto;
    width: auto;
    max-height: calc(100vh - 150px);
    box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 1px 2px 0 rgba(0, 34, 102, 0.2);
  }

  .s-header__subnav::before {
    content: '';
    position: absolute;
    top: 0;
    right: 10px;
    left: 10px;
    z-index: 1;
    display: block;
    height: 1px;
    background-color: #e4e7ee;
  }

  .s-header__subnav-link {
    position: relative;
    display: block;
    width: 100%;
    padding-right: 30px;
    padding-left: 65px;
    background-color: #fff;
    background-repeat: no-repeat;
    line-height: 45px;
    text-align: left;
    white-space: nowrap;
  }

  .s-header__subnav-link:focus {
    z-index: 1;
    outline: 2px solid #3af;
  }

  .s-header__subnav-link:hover {
    background-color: #f4f6f8;
  }

  .s-header__subnav-link:active {
    background-color: #e8e9ed;
    outline: none;
  }

  .s-header__subnav-link::after {
    content: '';
    position: absolute;
    right: 10px;
    bottom: 0;
    left: 10px;
    display: block;
    height: 1px;
    background-color: #e4e7ee;
  }

  .s-header__subnav-link--my-profile {
    position: relative;
  }

  .s-header__nav-avatar {
    position: absolute;
    top: 7px;
    left: 17px;
    width: 30px;
    height: 30px;
  }

  .s-header__subnav-link--accessibility {
    background-image: url(~@assets/imgs/nav_icons/accessibility_icon.svg);
    background-position: 9.09% 50%;
    background-size: 25px auto;
  }

  .s-header__subnav-link--interpreter-id {
    background-image: url(~@assets/imgs/nav_icons/header_interpreter_id.svg);
    background-position: 9.09% 50%;
    background-size: 25px auto;
  }

  .s-header__subnav-link--calendar {
    background-image: url(~@assets/imgs/nav_icons/calendar_mobile_footer_icon.svg);
    background-position: 10% 50%;
    background-size: 23px auto;
  }

  .s-header__subnav-link--docs {
    background-image: url(~@assets/imgs/nav_icons/docs_nav_icon.svg);
    background-position: 9.09% 50%;
    background-size: 18px auto;
  }

  .s-header__subnav-link--payments {
    background-image: url(~@assets/imgs/nav_icons/header_icon_payments.svg);
    background-position: 9.09% 50%;
    background-size: 31px auto;
  }

  .s-header__subnav-link--password {
    background-image: url(~@assets/imgs/nav_icons/header_icon_password.svg);
    background-position: 11.36% 50%;
    background-size: 17px auto;
  }

  .s-header__subnav-link--net-test {
    background-image: url(~@assets/imgs/nav_icons/header_icon_test.svg);
    background-position: 10.45% 50%;
    background-size: 20px auto;
  }

  .s-header__subnav-link--video-test {
    background-image: url(~@assets/imgs/nav_icons/video_icon.svg);
    background-position: 9.55% 50%;
    background-size: 23px auto;
  }

  .s-header__subnav-link--pref {
    background-image: url(~@assets/imgs/nav_icons/preferences.svg);
    background-position: 9.55% 50%;
    background-size: 22px auto;
  }

  .s-header__subnav-link--sign-out {
    background-image: url(~@assets/imgs/nav_icons/header_icon_signout.svg);
    background-position: 10% 50%;
    background-size: 23px auto;
  }

  .s-header__subnav-link.router-link-active {
    color: #646d8c;
    color: var(--color-primary);
  }

  .s-header__page-name {
    display: none;
  }

  .s-header__back-btn {
    position: relative;
    flex-shrink: 0;
    padding-left: 28px;
    font-size: 12px;
  }

  .s-header__back-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 28px;
    height: 100%;
    background-image: url(~@assets/imgs/arrows/big_arrow_to_left.svg);
    background-position: 95% 50%;
    background-size: 10px auto;
    background-repeat: no-repeat;
  }

  @media (max-width: 767px) {
    .s-header {
      height: 45px;
      padding-right: 50px;
    }

    .s-header__usual-link,
    .s-header__account-cont,
    .s-header__godmode-btn {
      display: none;
    }

    .s-header__logo {
      flex-shrink: 0;
      background-size: 25px auto;
    }

    .s-header__logo::after {
      display: none;
    }

    .s-header__page-name {
      display: block;
      flex-grow: 1;
      align-self: center;
      margin: 0;
      padding: 0;
      font-weight: normal;
      font-size: 12px;
      text-align: center;
    }
  }
</style>
