(<template>
  <main :class="{'logged-in-root--is-godmode': $isGodmode(),
                 'logged-in-root--is-punchout': punchOutRedirectUrl,
                 'logged-in-root--is-dangerous-edit': isDangerousEdit}"
        class="logged-in-root">
    <godmode-info-banner v-if="$isGodmode()" />
    <punchout-info-banner v-if="punchOutRedirectUrl" />
    <contact-info-banner v-if="isEmailVerificationRequired"
                         :banner-type="'email'" />
    <contact-info-banner v-if="userIsNotInterpreter && !isPhoneVerified"
                         :banner-type="'phone'" />
    <upgrade-for-translation-banner v-if="userIsBusiness" />
    <new-mobile-apps-banner v-if="showNewAppBanner" />
    <component :is="`${rolePrefix}-header`"
               v-if="showHeader" />
    <dangerous-fields-banner v-if="showDangerousBanner"
                             :message="dangerousFieldMessage"
                             :assignment-link="dangerousFieldLink" />
    <mobile-sub-navigation v-if="mobileNavOpenedState" />
    <!-- <timezone-banner v-if="showTimezoneWarning && canShowBanner"
                     @onupdated="onUpdateTimezone" /> -->
    <payout-method-banner v-if="showPayoutMethodBanner" />
    <!-- Paused User Banner -->
    <paused-account-banner v-if="isAccountPaused && !translationPaths" />
    <viewers-bar v-if="$isGodmode() && $route.meta.viewers" />
    <router-view v-if="!mobileNavOpenedState" />
    <component :is="`${rolePrefix}-footer`"
               v-if="showFooter"
               class="logged-in-root__footer" />
  </main>
</template>)

<script>
  import {mapState, mapGetters} from 'vuex';
  import BuyerHeader from '@/components/navigations/b_Header';
  import ManagerHeader from '@/components/navigations/m_Header';
  import ServerHeader from '@/components/navigations/s_Header';
  import BuyerFooter from '@/components/navigations/b_Footer';
  import ManagerFooter from '@/components/navigations/m_Footer';
  import ServerFooter from '@/components/navigations/s_Footer';
  import MobileSubNav from '@/components/navigations/sh_MobileSubNavigation';
  import TimezoneBanner from '@/components/shared_components/banners/TimezoneBanner';
  import PayoutMethodBanner from '@/components/shared_components/banners/PayoutMethodBanner';
  import GodmodeInfoBanner from '@/components/shared_components/banners/GodmodeInfoBanner';
  import PuchoutInfoBanner from '@/components/shared_components/banners/PuchoutInfoBanner';
  import PausedAccountBanner from '@/components/shared_components/banners/PausedAccountBanner';
  import ViewersBar from '@/components/shared_components/current_viewers/ViewersBar';
  import ContactInfoBanner from '@/components/shared_components/banners/ContactInfoBanner';
  import DangerousFieldsBanner from '@/components/shared_components/banners/DangerousFieldsBanner';
  import UpgradeForTranslationBanner from '@/components/shared_components/banners/UpgradeForTranslationBanner';
  import NewMobileAppsBanner from '@/components/shared_components/banners/NewMobileAppsBanner';

  export default {
    asyncData({store}) {
      let promisesArr = '';

      if (!store.state.selectsData.language || !store.state.selectsData.language.length) {
        promisesArr = [...promisesArr, store.dispatch('fetchSelectList', 'language')];
      }
      if (!store.state.selectsData.qualification || !store.state.selectsData.qualification.length) {
        promisesArr = [...promisesArr, store.dispatch('fetchSelectList', 'qualification')];
      }

      if (store.getters['UserInfoStore/userIsManager']
        && (!store.state.selectsData.managers || !store.state.selectsData.managers.length)) {
        promisesArr = [...promisesArr, store.dispatch('fetchSelectList', 'managers')];
      }

      return promisesArr ? Promise.all(promisesArr) : '';
    },
    components: {
      'godmode-info-banner': GodmodeInfoBanner,
      'punchout-info-banner': PuchoutInfoBanner,
      'payout-method-banner': PayoutMethodBanner,
      'contact-info-banner': ContactInfoBanner,
      'paused-account-banner': PausedAccountBanner,
      'upgrade-for-translation-banner': UpgradeForTranslationBanner,
      'new-mobile-apps-banner': NewMobileAppsBanner,
      'dangerous-fields-banner': DangerousFieldsBanner,
      'b-header': BuyerHeader,
      'm-header': ManagerHeader,
      's-header': ServerHeader,
      'b-footer': BuyerFooter,
      'm-footer': ManagerFooter,
      's-footer': ServerFooter,
      'mobile-sub-navigation': MobileSubNav,
      'timezone-banner': TimezoneBanner,
      'viewers-bar': ViewersBar
    },
    data() {
      return {
        showTimezoneWarning: false
      };
    },
    computed: {
      ...mapState('OneAssignmentStore', {
        mobileChatActive: (state) => state.mobileChatOpened,
        storeJobObj: ({storeJobObj}) => storeJobObj || {}
      }),
      ...mapState('PostInterpretationStore', {
        isUnfinishedEditJob: (state) => state.editingJob,
        editingUrl: (state) => state.urlEditingJob
      }),
      ...mapGetters('TTAuthStore', ['punchOutRedirectUrl']),
      ...mapGetters('UserInfoStore', [
        'userServerTimestamp',
        'userIsInterpreter',
        'userIsNotInterpreter',
        'userIsManager',
        'userIsBusiness',
        'userIsEnterpriseMember',
        'useHasInstalledMobileApp',
        'defaultPayoutMethod',
        'accountStatus',
        'isPhoneVerified',
        'isEmailVerificationRequired'
      ]),
      translationPaths() { return ['ServerAllProjects', 'ServerOneProject'].includes(this.$route.name); },
      clientWidth() { return this.$store.state.GlobalStore.clientWidth; },
      rolePrefix() {
        if (this.userIsInterpreter) return 's';
        else if (this.userIsNotInterpreter) return 'b';
        else if (this.userIsManager) return 'm';
      },
      mobileNavOpenedState() { return this.$store.getters.showMobileNav && this.clientWidth < 768; },
      // canShowBanner() { return this.$route.name != 'BuyerInterpreters'; },
      showHeader() { return !this.mobileChatActive; },
      showFooter() { return !this.mobileChatActive; },
      showPayoutMethodBanner() { return this.userIsInterpreter && !this.defaultPayoutMethod; },
      isAccountPaused() { return this.accountStatus == 'paused'; },
      isBookingPage() { return this.$route.name === 'BuyerPostInterpretation'; },
      isDangerousEdit() { return this.$route.query.dangerousEdit === 'true'; },
      cancelledDay() { return this.$moment(this.storeJobObj.cancelledAt).format('DD.MM.YYYY'); },
      cancelledTime() { return this.$moment(this.storeJobObj.cancelledAt).format('H:mm'); },
      warningText() {
        const template = this.$gettext('Your previous assignment &nbsp<strong>(#%{id})</strong>&nbsp has been canceled on %{day} at %{time} by Salita because you made modifications to it. Please create a new assignment with your changes and rebook it.');
        return this.$gettextInterpolate(template, {id: this.storeJobObj.id, day: this.cancelledDay, time: this.cancelledTime});
      },
      editingText() { return this.$gettext('You have an incomplete assignment. Don\'t forget to fill it out.'); },
      dangerousFieldMessage() { return this.isDangerousEdit ? this.warningText : this.editingText; },
      dangerousFieldLink() { return this.isDangerousEdit ? '' : this.editingUrl; },
      showDangerousBanner() {
        // eslint-disable-next-line no-mixed-operators
        return this.isDangerousEdit || this.isUnfinishedEditJob && !this.isBookingPage;
      },
      showNewAppBanner() { return this.userIsInterpreter && !this.useHasInstalledMobileApp; }
    },
    watch: {
      clientWidth() {
        this.$store.commit('toggleMobileNav', true);
      }
    },
    methods: {
      onUpdateTimezone() {
        this.showTimezoneWarning = false;
        this.$store.commit('GlobalStore/startProgress');
        setTimeout(() => {
          window.location.reload();
        }, 0);
      }
    },
    mounted() {
      if (this.userServerTimestamp) {
        this.showTimezoneWarning = this.$moment().local().utcOffset() != this.$moment().utcOffset(this.userServerTimestamp).utcOffset();
      }
      this.$store.dispatch('PostInterpretationStore/restoreEditingData');
    }
  };
</script>

<style src="@/assets/tikktalk/css/godmode_banner.css"></style>

<style scoped>
  .logged-in-root {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    padding-top: 60px;
    padding-bottom: 50px;
    background-color: #f4f5f7;
  }

  .logged-in-root--is-dangerous-edit {
    padding-bottom: 65px;
  }

  @media (max-width: 767px) {
    .logged-in-root {
      padding-top: 45px;
      padding-bottom: 70px;
    }
  }
</style>
