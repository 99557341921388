(<template>
  <div class="punchout-info-banner">
    <span>{{ punchoutTitle }}</span>
    <div class="punchout-info-banner__user-info-cont">
      <router-link v-if="userIsEnterpriseMember"
                   :to="$makeRoute({name: 'BuyerDepartments'})"
                   class="punchout-info-banner__item">{{ enterpriseName }}</router-link>
      <router-link v-if="userIsEnterpriseMember"
                   :to="$makeRoute({name: 'BuyerEmployees'})"
                   class="punchout-info-banner__item">{{ fullName }}</router-link>
      <interpreter-blocked-status v-if="isBlockedByStatus && userIsInterpreter"
                                  :interpreter-data="additionalUserInfo"
                                  class="punchout-info-banner__status-btn" />
      <span v-if="!userIsEnterpriseMember"
            class="punchout-info-banner__item">{{ fullName }}</span>
      <a v-if="userEmail"
         :href="`mailto:${userEmail}`"
         class="punchout-info-banner__item">{{ userEmail }}</a>
    </div>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import helpers from '@/helpers';
  import InterpreterBlockedStatus from '@/components/interpreters_components/InterpreterBlockedStatus';

  export default {
    components: {
      'interpreter-blocked-status': InterpreterBlockedStatus
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsEnterpriseMember',
        'fullName',
        'userEmail',
        'enterpriseName',
        'userIsInterpreter',
        'additionalUserInfo'
      ]),
      punchoutTitle() {
        const template = this.$gettext('%{domain} order: You are logged in as');
        return this.$gettextInterpolate(template, {domain: this.$getString('domainData', 'platformName')});
      },
      isBlockedByStatus() {
        return helpers.interpreterInfo.іsBlockedInterpreter(this.additionalUserInfo);
      },
    }
  };
</script>

<style scoped>
  .punchout-info-banner {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 11;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 10px;
    background-color: #003855;
    color: white;
  }

  .punchout-info-banner__user-info-cont {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 100%;
    padding-right: 10px;
  }

  .punchout-info-banner__item {
    display: block;
    margin-left: 5px;
    font-weight: bold;
  }

  .punchout-info-banner__item::after {
    content: ',';
  }

  .punchout-info-banner__item:last-child::after {
    content: '';
  }

  .punchout-info-banner__status-btn {
    padding-left: 10px;
  }

  @media (max-width: 767px) {
    .punchout-info-banner {
      overflow: auto;
      height: 90px;
      font-size: 11px;
    }

    .punchout-info-banner__item {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>
