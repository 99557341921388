(<template>
  <header class="b-header">
    <span v-if="showUserCountry"
          class="b-header__country-test">{{ userCountry }}</span>
    <div class="b-header__logo-print"></div>
    <router-link v-if="!showBackBtn"
                 :to="$makeRoute({name: 'ManagerAllAssignments'})"
                 class="b-header__logo" />
    <button v-if="showBackBtn"
            class="b-header__back-btn"
            @click="historyBack">{{ $gettext('Back') }}</button>
    <template v-for="(menu,index) in leftMenu">
      <router-link v-if="menu.type === 'router-link' && menu.condition && !menu.html"
                   :key="index"
                   :to="menu.action"
                   :class="menu.classNames">{{ menu.name }}</router-link>
      <router-link v-else-if="menu.type === 'router-link' && menu.condition && menu.html"
                   :key="index"
                   :to="menu.action"
                   :class="menu.classNames"><span v-html="menu.html"></span></router-link>
      <button v-else-if="menu.type === 'button' && menu.condition"
              :key="index"
              :class="menu.classNames"
              @click="menu.action">{{ menu.name }}</button>
    </template>
    <div class="b-header__account-cont">
      <p v-if="$isGodmode()"
         class="sk-btn sk-btn--red b-header__godmode-btn">{{ $gettext('Admin mode') }}</p>
      <sk-input :placeholder="searchPlaceholder"
                :preselected-value="searchKey"
                :input-icon="'search'"
                :reg-exp="/[^0-9]/g"
                :mob-numeric-keyboard="true"
                class="b-header__job-search-field"
                @enterpressed="openJobOnGodmod" />
      <button ref="accountBtn"
              class="b-header__account-btn"
              @click.stop="toggleSubnav">
        <div class="m-account__btn-text">
          {{ $gettext('My account') }}
        </div>
        <tt-avatar :own="true"
                   class="b-header__account-avatar" />
        <company-avatar v-if="!userIsNotEnterpriseMember"
                        :own="true"
                        class="b-header__account-logo" />
      </button>
      <nav v-if="subnavShown"
           class="b-header__subnav">
        <template v-for="(menu,index) in rightMenu">
          <router-link v-if="menu.type === 'router-link' && menu.condition"
                       :key="index"
                       :to="menu.action"
                       :class="menu.classNames">
            <tt-avatar v-if="menu.hasAvatar"
                       :own="true"
                       class="b-header__nav-avatar" />
            {{ menu.name }}</router-link>
          <button v-else-if="menu.type === 'button' && menu.condition"
                  :key="index"
                  :class="menu.classNames"
                  @click="menu.action">{{ menu.name }}</button>
          <a v-else-if="menu.type === 'link' && menu.condition"
             :key="index"
             :class="menu.classNames"
             :href="menu.href">{{ menu.name }}</a>
        </template>
      </nav>
    </div>
    <h1 class="b-header__page-name">{{ mobileTitle }}</h1>
  </header>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import constants from '@/modules/constants';

  const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');

  export default {
    data() {
      return {
        openedSubnav: false,
        clientWidth: 0,
        showAdvert: !this.$isGodmode() && this.$store.getters['UserInfoStore/showAdvertisement'],
        searchKey: ''
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userUid',
        'userIsInterpreter',
        'userIsNotEnterpriseMember',
        'userIsBusiness',
        'userIsEnterpriseMember',
        'userCanShowStatistics',
        'userCanShowStaffingButton',
        'allowInterpretationServices',
        'allowNewTranslationServices'
      ]),
      supportLink() {
        return this.userIsInterpreter
          ? 'https://support.salita.no/collection/362-tolk'
          : 'https://support.salita.no/collection/380-tolkebruker';
      },
      userCountry() {
        return this.$store.state.userCountry || 'countryNotDetected';
      },
      showUserCountry() {
        // enable this cookie to show Business country
        if (isBrowser()) {
          return this.$cookieManager.getCookie('showUserCountry');
        }
      },
      searchPlaceholder() { return this.$gettext('Job ID'); },
      leftMenu() {
        return [
          {
            name: this.$gettext('My assignments'),
            type: 'router-link',
            classNames: 'b-header__usual-link',
            action: this.assignmentsLink,
            condition: true
          },
          {
            name: this.$gettext('Translation assignments'),
            type: 'router-link',
            classNames: 'b-header__usual-link',
            action: this.projectsLink,
            condition: true
          },
          {
            name: this.$gettext('Customers'),
            type: 'router-link',
            classNames: 'b-header__usual-link',
            action: this.customersLink,
            condition: true
          },
          {
            name: this.$gettext('Suppliers'),
            type: 'router-link',
            classNames: 'b-header__usual-link',
            action: this.suppliersLink,
            condition: true
          },
          {
            name: this.$gettext('Interpretation Skills'),
            type: 'router-link',
            classNames: 'b-header__usual-link',
            action: this.interpretationSKillsLink,
            condition: true
          },
          {
            name: this.$gettext('Translation Skills'),
            type: 'router-link',
            classNames: 'b-header__usual-link',
            action: this.translationSKillsLink,
            condition: true
          },
          {
            name: this.$gettext('Enterprises'),
            type: 'router-link',
            classNames: 'b-header__usual-link',
            action: this.enterprisesLink,
            condition: true
          }
        ];
      },
      rightMenu() {
        return [
          {
            name: this.$gettext('My profile'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--my-profile',
            action: this.$makeRoute({name: 'ManagerUserProfileEdit', params: {id: this.userUid}}),
            hasAvatar: true,
            condition: true
          },
          {
            name: this.$gettext('Accessibility'),
            type: 'button',
            classNames: 'b-header__subnav-link b-header__subnav-link--accessibility',
            action: this.openAccessibilityModal,
            condition: true
          },
          {
            name: this.$gettext('Settings'),
            type: 'button',
            classNames: 'b-header__subnav-link b-header__subnav-link--pref',
            action: this.openManagerConsole,
            condition: true
          },
          {
            name: this.$gettext('Sign out'),
            type: 'button',
            classNames: 'b-header__subnav-link b-header__subnav-link--sign-out',
            action: this.logOut,
            condition: true
          }
        ];
      },
      assignmentsLink() {
        return this.$makeRoute({
          name: 'ManagerAllAssignments',
          query: {
            view: 'urgent',
            page: 1,
            items: this.$store.state.filterItemsAmount
          }
        });
      },
      projectsLink() {
        return this.$makeRoute({
          name: 'ManagerAllProjects',
          query: {
            view: 'this_week',
            page: 1,
            items: this.$store.state.filterItemsAmount
          }
        });
      },
      customersLink() {
        return this.$makeRoute({
          name: 'ManagerAllCustomers',
          query: {
            view: 'all',
            page: 1,
            items: this.$store.state.filterItemsAmount
          }
        });
      },
      suppliersLink() {
        return this.$makeRoute({
          name: 'ManagerAllSuppliers',
          query: {
            view: 'active',
            page: 1,
            items: this.$store.state.filterItemsAmount
          }
        });
      },
      interpretationSKillsLink() {
        return this.$makeRoute({
          name: 'ManagerAllInterpretationSkills',
          query: {
            view: 'approved',
            page: 1,
            items: this.$store.state.filterItemsAmount
          }
        });
      },
      translationSKillsLink() {
        return this.$makeRoute({
          name: 'ManagerAllTranslationSkills',
          query: {
            view: 'approved',
            page: 1,
            items: this.$store.state.filterItemsAmount
          }
        });
      },
      enterprisesLink() {
        return this.$makeRoute({
          name: 'ManagerAllEnterprises',
          query: {
            page: 1
          }
        });
      },
      mobileTitle() {
        return this.$store.state.mobileTitle || this.$getString('metaData', 'shortTitle');
      },
      subnavShown() {
        return this.openedSubnav && this.clientWidth >= 768;
      },
      showBackBtn() {
        return this.$store.state.subPage && this.$store.state.GlobalStore.touchScreen;
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      openAccessibilityModal() {
        this.closeModal();
        this.$store.commit('ModalStore/setModal', {
          component: 'accessibility-modal',
        });
      },
      toggleSubnav(e) {
        this.$store.commit('SelectsStore/closeOpenedSelect');
        this.toggleAbsoluteBlocks();
        this.openedSubnav = !this.openedSubnav;
        if (e.target.className.indexOf('b-header__account-') != -1) {
          this.$refs.accountBtn.blur();
        }
      },
      toggleAbsoluteBlocks(e) {
        if (e && (e.target.className.indexOf('b-header__account-') != -1 && e.target.className.indexOf('b-header__notifications-') != -1)) {
          return;
        }
        this.openedSubnav = false;
      },
      setClientWidth() {
        this.clientWidth = document.documentElement.clientWidth;
      },
      historyBack() {
        if (document.referrer && document.referrer.indexOf('sms') != -1) {
          this.$router.push('/');
        } else {
          const path = this.$route.fullPath;
          this.$router.back();
          setTimeout(() => {
            if (this.$route.fullPath == path) this.$router.push('/');
          }, 500);
        }
      },
      logOut() {
        this.$store.dispatch('TTAuthStore/logOut').then(() => {
          // set cookie for preventing mobile app redirect after clicking on logout button
          this.$cookieManager.setCookie('preventMobileAppRedirect', true, {path: '/', expires: 2592000});
          setTimeout(() => {
            this.$goToExternalLoginPage();
          }, 0);
        });
      },
      openManagerConsole() {
        const managersUrl = constants.API_PATH + '/management_console/managers';
        window.open(managersUrl, '_blank').focus();
      },
      openJobOnGodmod(searchKey) {
        const railsGodmodlink = constants.API_PATH + `/radmin/job_search?term=${searchKey}`;
        window.open(railsGodmodlink, '_blank').focus();
      }
    },
    mounted() {
      this.setClientWidth();
      document.addEventListener('click', this.toggleAbsoluteBlocks);
      window.addEventListener('resize', this.setClientWidth);
    }
  };
</script>
<style>
.b-header__job-search-field .sk-input__field-icon {
  left: 10px;
}

.b-header__job-search-field .sk-input__input--with-icon {
  padding-right: 10px;
  padding-left: 40px;
  border-radius: 4px !important;
}
</style>
<style scoped>

.b-header__job-search-field {
  width: 150px;
  margin: auto;
  margin-right: 5px;
  padding: 5px;
}
</style>
