(<template>
  <header class="b-header">
    <span v-if="showUserCountry"
          class="b-header__country-test">{{ userCountry }}</span>
    <div class="b-header__logo-print"></div>
    <router-link v-if="!showBackBtn"
                 :to="$makeRoute({name: 'BuyerHome'})"
                 class="b-header__logo" />
    <button v-if="showBackBtn"
            class="b-header__back-btn"
            @click="historyBack">{{ $gettext('Back') }}</button>
    <template v-for="(menu, index) in leftMenu">
      <router-link v-if="menu.type === 'router-link' && menu.condition && !menu.html"
                   :key="index"
                   :to="menu.action"
                   :class="menu.classNames">{{ menu.name }}</router-link>
      <router-link v-else-if="menu.type === 'router-link' && menu.condition && menu.html"
                   :key="index"
                   :to="menu.action"
                   :class="menu.classNames"><span v-html="menu.html"></span></router-link>
      <button v-else-if="menu.type === 'button' && menu.condition"
              :key="index"
              :class="menu.classNames"
              @click="menu.action">{{ menu.name }}</button>
      <div v-else-if="menu.type === 'div' && menu.condition"
           :key="index"
           :class="menu.classNames"></div>
    </template>
    <div class="b-header__account-cont">
      <p v-if="$isGodmode()"
         class="sk-btn sk-btn--red b-header__godmode-btn">{{ $gettext('Admin mode') }}</p>
      <a :href="'https://support.salita.no/collection/380-tolkebruker'"
         target="_blank"
         class="b-header__usual-link">{{ $gettext('Help center') }}</a>
      <button ref="accountBtn"
              :class="{'b-header__account-btn--enterprise': !userIsNotEnterpriseMember}"
              class="b-header__account-btn"
              @click.stop="toggleSubnav">
        <tt-avatar :own="true"
                   class="b-header__account-avatar" />
        <company-avatar v-if="!userIsNotEnterpriseMember"
                        :own="true"
                        class="b-header__account-logo" />
        <div class="hamburger-lines">
          <span class="line line1"></span>
          <span class="line line2"></span>
          <span class="line line3"></span>
        </div>
      </button>
      <nav v-if="subnavShown"
           class="b-header__subnav">
        <template v-for="(menu, index) in rightMenu">
          <router-link v-if="menu.type === 'router-link' && menu.condition"
                       :key="index"
                       :to="menu.action"
                       :class="menu.classNames">
            <tt-avatar v-if="menu.hasAvatar"
                       :own="true"
                       class="b-header__nav-avatar" />
            {{ menu.name }}</router-link>
          <button v-else-if="menu.type === 'button' && menu.condition"
                  :key="index"
                  :class="menu.classNames"
                  @click="menu.action">{{ menu.name }}</button>
          <a v-else-if="menu.type === 'link' && menu.condition"
             :key="index"
             :class="menu.classNames"
             :href="menu.href">{{ menu.name }}</a>
        </template>
      </nav>
    </div>
    <h1 class="b-header__page-name">{{ mobileTitle }}</h1>
  </header>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  // import AdvertisementModal from '@/components/shared_components/modals/AdvertisementModal';

  const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');

  export default {
    // components: {
    //   'advertisement-modal': AdvertisementModal
    // },
    data() {
      return {
        openedSubnav: false,
        clientWidth: 0,
        showAdvert: !this.$isGodmode() && this.$store.getters['UserInfoStore/showAdvertisement']
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userUid',
        'userIsInterpreter',
        'userIsNotEnterpriseMember',
        'userIsBusiness',
        'userIsEnterpriseMember',
        'userCanShowStatistics',
        'userCanShowStaffingButton',
        'allowInterpretationServices',
        'allowNewTranslationServices',
        'isEmailVerificationRequired'
      ]),
      ...mapState('EnterpriseStore', {
        currentEmployee: (state) => state.currentEmployee || {}
      }),
      userCountry() {
        return this.$store.state.userCountry || 'countryNotDetected';
      },
      showUserCountry() {
        // enable this cookie to show Business country
        if (isBrowser()) {
          return this.$cookieManager.getCookie('showUserCountry');
        }
      },
      leftMenu() {
        if ((this.allowInterpretationServices === true && this.allowNewTranslationServices === true) || (this.userIsBusiness)) {
          return this.onBothLeftMenu;
        } else if (this.allowInterpretationServices === true && this.allowNewTranslationServices === false) {
          return this.interpretationOnlyLeftMenu;
        } else if (this.allowInterpretationServices === false && this.allowNewTranslationServices === true) {
          return this.newTranslationOnlyLeftMenu;
          // if flags are not defined or both are false ==> fallback to default
        } else {
          return this.interpretationOnlyLeftMenu;
        }
      },
      rightMenu() {
        if (this.allowInterpretationServices === true && this.allowNewTranslationServices === true) {
          return this.defaultRightMenu;
        } else if (this.allowInterpretationServices === true && this.allowNewTranslationServices === false) {
          return this.defaultRightMenu;
        } else if (this.allowInterpretationServices === false && this.allowNewTranslationServices === true) {
          return this.newTranslationOnlyRightMenu;
          // if flags are not defined or both are false ==> fallback to default
        } else {
          return this.defaultRightMenu;
        }
      },
      onBothLeftMenu() {
        return [
          {
            name: this.$gettext('Interpretation'),
            type: 'router-link',
            classNames: 'b-header__usual-link b-header__usual-link--first',
            action: {name: 'BuyerHome'},
            condition: true
          },
          {
            name: this.$gettext('My assignments'),
            type: 'router-link',
            classNames: 'b-header__usual-link',
            action: this.assignmentsLink,
            condition: true
          },
          {
            name: this.$gettext('Book a specific interpreter'),
            type: 'router-link',
            classNames: 'b-header__usual-link',
            action: this.capacityLink,
            condition: this.userIsEnterpriseMember && !this.isEmailVerificationRequired
          },
          {
            name: this.$gettext('Book an interpreter'),
            type: 'router-link',
            classNames: 'new-assignment-btn',
            action: this.$makeRoute({name: 'BuyerPostInterpretation'}),
            condition: true
          },
          {
            name: '',
            type: 'div',
            classNames: 'b-header__devider',
            condition: true
          },
          {
            name: this.$pgettext('translation', 'Translation'),
            type: 'router-link',
            classNames: 'b-header__usual-link b-header__usual-link--first',
            action: {name: 'BuyerAllProjects'},
            condition: true
          },
          {
            name: this.$gettext('Order translation'),
            type: 'router-link',
            classNames: 'new-assignment-btn',
            action: this.$makeRoute({name: 'BuyerPostTranslationProject'}),
            condition: true
          },
          {
            html: this.upgradeText,
            type: 'router-link',
            classNames: 'sk-btn sk-btn--white b-header__upgrade-btn',
            action: this.$makeRoute({name: 'BuyerUpgradeProfile'}),
            condition: this.userIsNotEnterpriseMember
          }
        ];
      },
      interpretationOnlyLeftMenu() {
        return [
          {
            name: this.$gettext('Home'),
            type: 'router-link',
            classNames: 'b-header__usual-link b-header__usual-link--first',
            action: {name: 'BuyerHome'},
            condition: true
          },
          {
            name: this.$gettext('My assignments'),
            type: 'router-link',
            classNames: 'b-header__usual-link',
            action: this.assignmentsLink,
            condition: true
          },
          {
            name: this.$gettext('Book a specific interpreter'),
            type: 'router-link',
            classNames: 'b-header__usual-link',
            action: this.capacityLink,
            condition: this.userIsEnterpriseMember && !this.isEmailVerificationRequired
          },
          {
            name: this.$gettext('Book an interpreter'),
            type: 'router-link',
            classNames: 'new-assignment-btn',
            action: this.$makeRoute({name: 'BuyerPostInterpretation'}),
            condition: true
          },
          {
            html: this.upgradeText,
            type: 'router-link',
            classNames: 'sk-btn sk-btn--white b-header__upgrade-btn',
            action: this.$makeRoute({name: 'BuyerUpgradeProfile'}),
            condition: this.userIsNotEnterpriseMember
          }
        ];
      },
      newTranslationOnlyLeftMenu() {
        return [
          {
            name: this.$pgettext('translation', 'Translation'),
            type: 'router-link',
            classNames: 'b-header__usual-link b-header__usual-link--first',
            action: {name: 'BuyerAllProjects'},
            condition: true
          },
          {
            name: this.$pgettext('translation', 'Order translation'),
            type: 'router-link',
            classNames: 'new-assignment-btn',
            action: this.$makeRoute({name: 'BuyerPostTranslationProject'}),
            condition: true
          },
          {
            html: this.upgradeText,
            type: 'router-link',
            classNames: 'sk-btn sk-btn--white b-header__upgrade-btn',
            action: this.$makeRoute({name: 'BuyerUpgradeProfile'}),
            condition: this.userIsNotEnterpriseMember
          }
        ];
      },
      defaultRightMenu() {
        return [
          {
            name: this.$gettext('My profile'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--my-profile',
            action: this.$makeRoute({name: 'BuyerUserProfileEdit', params: {id: this.userUid}}),
            hasAvatar: true,
            condition: true
          },
          {
            name: this.$gettext('Accessibility'),
            type: 'button',
            classNames: 'b-header__subnav-link b-header__subnav-link--accessibility',
            action: this.openAccessibilityModal,
            condition: true
          },
          {
            name: this.$gettext('Calendar'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--calendar',
            action: this.calendarLink,
            condition: true
          },
          {
            name: this.$gettext('Interpreters'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--interpreters',
            action: this.interpretersLink,
            condition: !this.isEmailVerificationRequired
          },
          {
            name: this.$gettext('My company'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--comp-info',
            action: this.$makeRoute({name: 'BuyerCompanyInfo'}),
            condition: !this.userIsNotEnterpriseMember && !this.isEmailVerificationRequired
          },
          {
            name: this.$gettext('Payments'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--payments',
            action: this.$makeRoute({name: 'BuyerPayments'}),
            condition: this.userIsNotEnterpriseMember
          },
          {
            name: this.$gettext('Change password'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--password',
            action: this.$makeRoute({name: 'BuyerChangePassword'}),
            condition: true
          },
          // TODO: Original page is not working anymore and for proving everything is works suppliers must pass video test
          {
            name: this.$gettext('Network test'),
            type: 'link',
            classNames: 'b-header__subnav-link b-header__subnav-link--net-test',
            href: 'https://test.webrtc.org',
            target: '_blank',
            condition: false
          },
          {
            name: this.$gettext('Notifications'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--pref',
            action: this.$makeRoute({name: 'BuyerPreferencesNotifications'}),
            condition: this.userIsBusiness || this.userIsEnterpriseMember
          },
          {
            name: this.$gettext('Video test'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--video-test',
            action: this.$makeRoute({name: 'BuyerTestVideo'}),
            condition: true
          },
          {
            name: this.$gettext('Statistics'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--statistics',
            action: this.$makeRoute({name: 'BuyerStatisticsChart', query: {view: 'interpretation'}, params: {chartId: 'line_type'}}),
            condition: this.userCanShowStatistics
          },
          {
            name: this.$gettext('Tickets'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--tickets',
            action: this.$makeRoute({name: 'BuyerTicketSystem', query: {view: 'opened', page: 1}}),
            condition: !this.isEmailVerificationRequired
          },
          {
            name: this.$gettext('Sign out'),
            type: 'button',
            classNames: 'b-header__subnav-link b-header__subnav-link--sign-out',
            action: this.logOut,
            condition: true
          }
        ];
      },
      newTranslationOnlyRightMenu() {
        return [
          {
            name: this.$gettext('My profile'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--my-profile',
            action: this.$makeRoute({name: 'BuyerUserProfileEdit', params: {id: this.userUid}}),
            hasAvatar: true,
            condition: true
          },
          {
            name: this.$gettext('My company'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--comp-info',
            action: this.$makeRoute({name: 'BuyerCompanyInfo'}),
            condition: !this.userIsNotEnterpriseMember
          },
          {
            name: this.$gettext('Change password'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--password',
            action: this.$makeRoute({name: 'BuyerChangePassword'}),
            condition: true
          },
          {
            name: this.$gettext('Tickets'),
            type: 'router-link',
            classNames: 'b-header__subnav-link b-header__subnav-link--tickets',
            action: this.$makeRoute({name: 'BuyerTicketSystem', query: {view: 'opened', page: 1}}),
            condition: true
          },
          {
            name: this.$gettext('Sign out'),
            type: 'button',
            classNames: 'b-header__subnav-link b-header__subnav-link--sign-out',
            action: this.logOut,
            condition: true
          }
        ];
      },
      calendarLink() {
        return this.$makeRoute({
          name: 'BuyerCalendar',
          query: {
            view: 'week',
            date: this.$moment().format('YYYY-MM-DD')
          }
        });
      },
      capacityLink() {
        return this.$makeRoute({
          name: 'BuyerCapacity',
          query: {
            view: 'day',
            date: this.$moment().format('YYYY-MM-DD'),
            page: 1
          }
        });
      },
      homeLink() {
        return this.$makeRoute({
          name: 'BuyerHome'
        });
      },
      assignmentsLink() {
        return this.$makeRoute({
          name: 'BuyerAllAssignments',
          query: {
            view: 'current',
            sort: 1,
            page: 1
          }
        });
      },
      interpretersLink() {
        return this.$makeRoute({
          name: 'BuyerInterpreters',
          query: {
            page: 1,
            availability_date: this.$moment().format('YYYY-MM-DD'),
            langFrom: '3'
          }
        });
      },
      mobileTitle() {
        return this.$store.state.mobileTitle || this.$getString('metaData', 'shortTitle');
      },
      subnavShown() {
        return this.openedSubnav && this.clientWidth >= 768;
      },
      showBackBtn() {
        return this.$store.state.subPage && this.$store.state.GlobalStore.touchScreen;
      },
      upgradeText() {
        return this.$gettext('Upgrade to<br />enterprise');
      }
    },
    methods: {
      closeModal() {
        this.$emit('closemodal');
      },
      openAccessibilityModal() {
        this.closeModal();
        this.$store.commit('ModalStore/setModal', {
          component: 'accessibility-modal',
        });
      },
      toggleSubnav(e) {
        this.$store.commit('SelectsStore/closeOpenedSelect');
        this.toggleAbsoluteBlocks();
        this.openedSubnav = !this.openedSubnav;
        if (e.target.className.indexOf('b-header__account-') != -1) {
          this.$refs.accountBtn.blur();
        }
      },
      toggleAbsoluteBlocks(e) {
        if (e && (e.target.className.indexOf('b-header__account-') != -1 && e.target.className.indexOf('b-header__notifications-') != -1)) {
          return;
        }
        this.openedSubnav = false;
      },
      setClientWidth() {
        this.clientWidth = document.documentElement.clientWidth;
      },
      historyBack() {
        if (document.referrer && document.referrer.indexOf('sms') != -1) {
          this.$router.push('/');
        } else {
          const path = this.$route.fullPath;
          this.$router.back();
          setTimeout(() => {
            if (this.$route.fullPath == path) this.$router.push('/');
          }, 500);
        }
      },
      logOut() {
        this.$store.dispatch('TTAuthStore/logOut').then(() => {
          // set cookie for preventing mobile app redirect after clicking on logout button
          this.$cookieManager.setCookie('preventMobileAppRedirect', true, {path: '/', expires: 2592000});
          setTimeout(() => {
            this.$goToExternalLoginPage();
          }, 0);
        });
      },
      closeAdvertisement() {
        this.showAdvert = false;
      }
    },
    mounted() {
      this.setClientWidth();
      document.addEventListener('click', this.toggleAbsoluteBlocks);
      window.addEventListener('resize', this.setClientWidth);
    }
  };
</script>
